<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg12 xl12>
        <v-data-table
          dense
          :headers="headers"
          :items="listaCompras"
          class="elevation-10"
          :search="search"
          :footer-props="{
            'items-per-page-options': [5],
          }"
          sortBy="data_compra"
          sortDesc
        >
          <!-- <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template> -->

          <template v-slot:top>
            <v-toolbar flat dense>
              <v-icon class="mr-2" color="primary">mdi-account-outline</v-icon>
              <v-toolbar-title>Compras do Cliente</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="blue darken-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregaLista"
                  >
                    mdi-refresh-circle
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="green darken-1"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialog = true"
                  >
                    mdi-cart
                  </v-icon>
                </template>
                <span>Nova Compra</span>
              </v-tooltip>
            </v-toolbar>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  id="tooltip"
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metEditItem(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
          <template v-slot:[`item.data_compra`]="{ item }">
            <span>{{ mixDataDBD(item.data_compra) }} </span>
          </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card min-width="500" min-height="300">
            <v-card-title>
              <span class="text-h5">{{ compFormTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="auto" md="6">
                    <v-text-field
                      type="date"
                      v-model="editedItem.data_compra"
                      label="Data compra"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="auto"
                    md="6"
                    class="d-flex justify-center"
                    align="center"
                  >
                    <v-radio-group v-model="editedItem.formapag" col dense>
                      <v-radio label="À vista" value="avista"></v-radio>
                      <v-radio label="Crediário" value="crediario"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      v-model="editedItem.detalhe"
                      label="Detalhe"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn
                dense
                class="mb-4 white--text"
                color="green darken-2"
                @click="metSalvar"
              >
                Salvar
              </v-btn>
              <v-btn
                dense
                class="ml-2 mb-4 white--text"
                color="blue darken-1"
                @click="metFechar"
              >
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";
import DatasMixin from "@/mixins/DatasMixin";
const { mapState, mapActions } = createNamespacedHelpers("lojaClientes");

export default {
  components: { ConfirmDialogue },
  mixins: [DatasMixin],
  name: "LojaClientesComprasLista",
  data: () => ({
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },
      {
        text: "Detalhe",
        align: "start",
        sortable: false,
        value: "detalhe",
      },
      {
        text: "Forma Pag.",
        value: "formapag",
        align: "start",
        sortable: false,
      },
      { text: "Compra", value: "data_compra", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    listaCompras: [],
    editedIndex: -1,
    editedItem: {
      id: null,
      detalhe: "",
      data_compra: "",
      id_cliente: 0,
    },
    defaultItem: {
      id: null,
      detalhe: "",
      data_compra: "",
      id_cliente: 0,
    },
    formapag: undefined,
    clienteLocal: {},
  }),

  computed: {
    ...mapState(["erro", "staClienteCompras"]),

    // clientesLista() {
    //   return this.staClienteCompras;
    // },
    compFormTitle() {
      return this.editedIndex === -1 ? "Nova Compra" : "Edita Compra";
    },
  },

  created() {
    register(this.$store);
    this.metRecarregaLista();
  },

  watch: {
    erro() {
      if (this.erro) {
        this.$root.snackbar.show({
          type: "error",
          message: this.erro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
    dialog(val) {
      val || this.metFechar();
    },
  },

  mounted() {
    //this.obterClientes();
  },

  methods: {
    ...mapActions([
      "actListarClienteCompras",
      "actEditarClienteCompra",
      "actCriarClienteCompra",
      "actEditarCliente",
    ]),

    initialize() {
      /*       if (this.clientes === null) {
        this.clientes = null;
      } */
    },
    login() {
      this.$router.push({ name: "Login" });
    },

    // selecionarClienteEdicao(cliente) {
    //   this.selecionarCliente({ cliente });
    //   this.$router.push({ name: "LojaClientesComprasForm" });
    // },

    // paraAtivacao(cliente) {
    //   this.ativarCliente({
    //     cliente: cliente,
    //     licenca: this.$store.state.login.licenca,
    //   });
    // },
    // resetarCliente() {
    //   this.resetarClienteSelecionado();
    // },
    // metVoltar() {
    //   this.$router.back();
    // },
    // metRecarregar() {
    //   // this.clientesLista();
    //   // this.$root.snackbar.show({
    //   //   type: "info",
    //   //   message: "Lista atualizada!",
    //   // });
    // },
    metEditItem(item) {
      this.editedIndex = this.listaCompras.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.data_compra = this.mixDataDBDCal(item.data_compra);
      this.dialog = true;
    },
    metFechar() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async metSalvar() {
      if (this.editedIndex > -1) {
        // this.editedItem.data_compra = this.dataForm(
        //   this.editedItem.data_compra
        // );
        await this.actEditarClienteCompra({
          compra: JSON.parse(JSON.stringify(this.editedItem)),
          licenca: this.$store.state.login.licenca,
        });
        this.metRecarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Compra salva!!",
        });
      } else {
        if (this.editedItem.formapag == "avista") {
          this.clienteLocal.id_cliente =
            this.$store.state.lojaClientes.clienteSelecionado.id_cliente;
          this.clienteLocal.ultima_compra = this.editedItem.data_compra;
          await this.actEditarCliente({
            cliente: JSON.parse(JSON.stringify(this.clienteLocal)),
            licenca: this.$store.state.login.licenca,
          });
        }
        this.editedItem.id_cliente =
          this.$store.state.lojaClientes.clienteSelecionado.id_cliente;
        await this.actCriarClienteCompra({
          compra: JSON.parse(JSON.stringify(this.editedItem)),
          licenca: this.$store.state.login.licenca,
        });

        this.metRecarregaLista();
        this.$root.snackbar.show({
          type: "success",
          message: "Compra criada!!!",
        });
      }
      this.metFechar();
    },
    async metRecarregaLista() {
      if (this.$store.state.lojaClientes.clienteSelecionado != undefined) {
        const cliente = this.$store.state.lojaClientes.clienteSelecionado;
        await this.actListarClienteCompras({
          id_cliente: cliente.id_cliente,
          licenca: this.$store.state.login.licenca,
        });
        this.listaCompras = this.staClienteCompras;
      }
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!!",
      });
    },
  },
};
</script>
