import { render, staticRenderFns } from "./LojaClientesComprasForm.vue?vue&type=template&id=49a7e6ac&"
import script from "./LojaClientesComprasForm.vue?vue&type=script&lang=js&"
export * from "./LojaClientesComprasForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports