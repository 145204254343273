<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                    @input="$v.clienteLocal.nome_cliente.$touch()"
                    @blur="$v.clienteLocal.nome_cliente.$touch()"-->
          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.nome_cliente"
                    label="Cliente"
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.cpf_cliente"
                    label="CPF"
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.celular_cli"
                    label="Celular"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.ultima_compra"
                    label="Ultima Compra"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.nome_mae"
                    label="Nome da mãe"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.endereco_cli"
                    label="Endereço"
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.bairro_cli"
                    label="Bairro"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.cidade_cli"
                    label="Cidade"
                  ></v-text-field>
                </v-col>

                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.uf_cli"
                    label="Estado"
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="clienteLocal.credito"
                    label="Credito"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: 'LojaClientesLista' })"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-6 mr-4 mb-4"
                  color="indigo"
                  v-bind="attrs"
                  v-on="on"
                  @click="$router.push({ path: 'LojaClientesForm' })"
                >
                  mdi-account-edit
                </v-icon>
              </template>
              <span>Cadastro Completo</span>
            </v-tooltip>
          </template>
          <template>
            <tableCompras />
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";
import tableCompras from "./LojaClientesComprasLista";

const { mapState, mapActions } = createNamespacedHelpers("lojaClientes");

export default {
  mixins: [validationMixin, DatasMixin],
  components: { tableCompras },
  // validations: {
  //   clienteLocal: {
  //     nome_cliente: { required },
  //     email: { required, email },
  //     cliente: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "LojaClientesComprasForm",
    tituloForm: "",
    clienteLocal: {},
    agora: undefined,
  }),
  mounted() {},
  computed: {
    ...mapState(["clienteSelecionado"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.nome_cliente.$dirty) return errors;
    //   !this.$v.clienteLocal.nome_cliente.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.email.$dirty) return errors;
    //   !this.$v.clienteLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.clienteLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // clienteErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.cliente.$dirty) return errors;
    //   !this.$v.clienteLocal.cliente.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.clienteLocal.senha.$dirty) return errors;
    //   !this.$v.clienteLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
  },

  watch: {
    clienteSelecionado(clienteNovo) {
      this.sincronizar(clienteNovo);
    },
  },

  created() {
    this.agora = new Date().toISOString();
    this.sincronizar(this.clienteSelecionado);
    this.clienteSelecionado
      ? (this.tituloForm = "Editar Cliente")
      : (this.tituloForm = "Novo Cliente");
    this.formataDatasDB();
  },

  methods: {
    ...mapActions([
      "selecionarCliente",
      "resetarCliente",
      "criarCliente",
      "actEditarCliente",
    ]),
    selecionarClienteCompras() {
      // this.selecionarCliente({ cliente });
      this.$router.push({ name: "LojaClientesComprasLista" });
    },
    formataDatasForm() {
      this.clienteLocal.data_nac_conju = this.mixDataFormD(
        this.clienteLocal.data_nac_conju
      );

      this.clienteLocal.ultima_compra = this.mixDataFormD(
        this.clienteLocal.ultima_compra
      );

      this.clienteLocal.data_consulta = this.mixDataFormD(
        this.clienteLocal.data_consulta
      );

      this.clienteLocal.data_nac_cli = this.mixDataFormD(
        this.clienteLocal.data_nac_cli
      );

      this.clienteLocal.data_cadastro = this.mixDataFormD(
        this.clienteLocal.data_cadastro
      );
    },

    formataDatasDB() {
      this.clienteLocal.data_nac_cli = this.mixDataDBD(
        this.clienteLocal.data_nac_cli
      );

      this.clienteLocal.ultima_compra = this.mixDataDBD(
        this.clienteLocal.ultima_compra
      );

      this.clienteLocal.data_nac_conju = this.mixDataDBD(
        this.clienteLocal.data_nac_conju
      );

      this.clienteLocal.data_consulta = this.mixDataDBD(
        this.clienteLocal.data_consulta
      );

      this.clienteLocal.data_cadastro = this.mixDataDBD(
        this.clienteLocal.data_cadastro
      );
    },

    async salvarCliente() {
      switch (this.clienteSelecionado) {
        case undefined: // não é utilizado para criar
          this.clienteLocal.data_cadastro = this.dataUTCForm(this.agora);
          console.log("1", this.clienteLocal.data_cadastro);
          this.formataDatasForm();
          this.criarCliente({
            cliente: this.clienteLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasForm();
          this.$root.snackbar.show({
            type: "success",
            message: "Cliente criado!!",
          });
          break;
        default:
          this.formataDatasForm();
          this.actEditarCliente({
            cliente: this.clienteLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          this.$root.snackbar.show({
            type: "success",
            message: "Usuário salvo!",
          });
      }
      //this.resetar();
    },

    sincronizar(novoCliente) {
      this.clienteLocal = Object.assign(
        {},
        novoCliente || {
          id_cliente: undefined,
          data_cadastro: this.agora,
          ultima_compra: undefined,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarCliente();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      this.clienteLocal.ultima_compra = this.clienteSelecionado.ultima_compra;
      // this.clienteLocal.email = this.clienteSelecionado.email;
      // this.clienteLocal.cliente = this.clienteSelecionado.cliente;
      // this.clienteLocal.senha = this.clienteSelecionado.senha;
      // this.clienteLocal.ativo = this.clienteSelecionado.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
